import axios from "axios";
import { useDisclosure } from "@mantine/hooks";
import React, { useEffect, useRef, useState } from "react";
import ModalPix from "./ModalPix";
import SidebarAvulso from "./SidebarAvulso";
import { Button, Divider, Grid, Group, Modal, Text } from "@mantine/core";
import { jsPDF } from "jspdf";
import { IconCheck, IconPrinter } from "@tabler/icons-react";

const EstacionamentoAvulso = () => {
  const [opened, { open, close }] = useDisclosure(false);
  const [openedModal, { open: openModal, close: closeModal }] =
    useDisclosure(false);
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [placa, setPlaca] = useState("placa");
  const [textoPlaca, setTextoPlaca] = useState("");
  const [limite, setLimite] = useState(8);
  const [inputVazio, setInputVazio] = useState("inputvazio3");
  const [mensagem, setMensagem] = useState("");
  const [estado, setEstado] = useState(false);
  const [success, setSuccess] = useState(false);
  const [tempo, setTempo] = useState("01:00:00");
  const [valorCobranca, setValorCobranca] = useState(0);
  const [valorcobranca2, setValorCobranca2] = useState(null);
  const [notification, setNotification] = useState(true);
  const [pixExpirado, setPixExpirado] = useState("");
  const [txid, setTxId] = useState("");
  const [onOpen, setOnOpen] = useState(false);
  const [cont, setCont] = useState(0);
  const [teste, setTeste] = useState("");
  const [selectedButton, setSelectedButton] = useState("01:00:00");
  const [loader, setLoader] = useState(false);
  const [date, setDate] = useState("");
  const [emissao, setEmissao] = useState("");
  const [validade, setValidade] = useState("");

  const handleButtonClick = (buttonIndex) => {
    setSelectedButton(buttonIndex);
    setTempo(buttonIndex);
    const tempo1 = buttonIndex;
    if (tempo1 === "02:00:00") {
      setValorCobranca2(valorCobranca * 2);
    } else if (tempo1 === "01:00:00") {
      setValorCobranca2(valorCobranca);
    } else if (tempo1 === "01:30:00") {
      setValorCobranca2(valorCobranca * 1.5);
    } else if (tempo1 === "00:30:00") {
      setValorCobranca2(valorCobranca / 2);
    }
  };

  function validarPlaca(placa) {
    const regexPlacaAntiga = /^[a-zA-Z]{3}\d{4}$/;
    const regexPlacaNova =
      /^([A-Z]{3}[0-9][A-Z0-9][0-9]{2})|([A-Z]{4}[0-9]{2})$/;

    if (regexPlacaAntiga.test(placa) || regexPlacaNova.test(placa)) {
      return true;
    } else {
      return false;
    }
  }

  const param = async () => {
    const requisicao = axios.create({
      baseURL: process.env.REACT_APP_HOST,
    });
    await requisicao
      .get("/parametros")
      .then((response) => {
        setValorCobranca(response.data.data.param.estacionamento.valorHora);
        setValorCobranca2(response.data.data.param.estacionamento.valorHora)
      })
      .catch(function (error) {
        localStorage.removeItem("user");
        localStorage.removeItem("token");
        localStorage.removeItem("perfil");
      });
  };

  const handlePlaca = () => {
    const clicado = document.getElementById("flexSwitchCheckDefault").checked;
    if (clicado === true) {
      setPlaca("placa2");
      setLimite(100);
      setInputVazio("inputvazio2");
    } else {
      setPlaca("placa");
      setLimite(8);
      setInputVazio("inputvazio3");
    }
  };

  const jae = () => {
    const sim = document.getElementById("flexSwitchCheckDefault").checked;
    if (sim === true) {
      setLimite(10);
    } else {
      setLimite(8);
    }
  };

  useEffect(() => {
    param();
    login();
  }, []);

  const login = async () => {
    const requisicao = axios.create({
      baseURL: process.env.REACT_APP_HOST,
    });
    await requisicao
      .post("/login", {
        login: process.env.REACT_APP_USER,
        senha: process.env.REACT_APP_PASSWORD,
      })
      .then((resposta) => {
        if (resposta.data.msg.resultado) {
          console.log(resposta.data.dados.usuario.perfil[0].perfil);
          localStorage.setItem("user", resposta.data.dados.usuario.id_usuario);
          localStorage.setItem("token", resposta.data.dados.token);
          localStorage.setItem("perfil", resposta.data.dados.usuario.perfil[0].perfil);
        } else {
          localStorage.removeItem("user");
          localStorage.removeItem("token");
          localStorage.removeItem("perfil");
        }
      }
      )
      .catch((error) => {
        console.log(error);
      }
      );
  }


  useEffect(() => {
    const clicado = document.getElementById("flexSwitchCheckDefault").checked;
    if (clicado === false) {
      if (
        textoPlaca.at(4) === "1" ||
        textoPlaca.at(4) === "2" ||
        textoPlaca.at(4) === "3" ||
        textoPlaca.at(4) === "4" ||
        textoPlaca.at(4) === "5" ||
        textoPlaca.at(4) === "6" ||
        textoPlaca.at(4) === "7" ||
        textoPlaca.at(4) === "8" ||
        textoPlaca.at(4) === "9" ||
        textoPlaca.at(4) === "0"
      ) {
        setPlaca("placa3");
      } else {
        setPlaca("placa");
        setCont(0);
      }
      setTeste(textoPlaca.replace("-", ""));
    }
  }, [textoPlaca]);



  const calcularValidade = (horaInicio, duracao) => {
    const [horas, minutos, segundos] = duracao.split(':').map(Number);
    const dataInicio = new Date(`2000-01-01T${horaInicio}`);
    const dataValidade = new Date(dataInicio.getTime() + (horas * 3600000) + (minutos * 60000) + (segundos * 1000));
    const horaValidade = dataValidade.toLocaleTimeString('pt-BR', { timeZone: 'America/Sao_Paulo' });
    return horaValidade;
  };

  async function gerarPDF(data) {
    // Cria um novo objeto jsPDF
    const pdfWidth = 80; // 100 mm
    const pdfHeight = 100; // 150 mm
    const pdf = new jsPDF({
      unit: 'mm',
      format: [pdfWidth, pdfHeight],
    });

    const tamanhoFonte = 12;

    // Posições para posicionar o conteúdo do PDF
    const x = 18 ;
    const y = 15;

    // Iniciar a criação do PDF e adicionar o conteúdo da div
    pdf.setFontSize(tamanhoFonte);
    pdf.text("CONSEPRO TAQUARA", x, y);
    pdf.text("- - - - - - - - - - - - - - - - - - - - - - - -", 10 , y + 5);
    pdf.setFontSize(tamanhoFonte - 2);
    pdf.text("Tipo: Estacionamento avulso" , x, y + 12);
    pdf.text(`Início: ${date} - ${emissao}` , x, y + 17);
    pdf.text(`Validade: ${date} - ${await calcularValidade(data2.chegada, data2.tempo)}` , x, y + 21);
    pdf.text("Placa: " + data2.placa , x, y + 25);
    pdf.text("Tempo: " + data2.tempoCredito , x, y + 29);
    pdf.text("Valor: R$ " + data2.valor , x, y + 33);
    pdf.setFontSize(tamanhoFonte);
    pdf.text("- - - - - - - - - - - - - - - - - - - - - - - -", 10 , y + 39);
    pdf.setFontSize(tamanhoFonte - 2);
    pdf.text("CNPJ: 89.668.040/0001-10", x, y + 43);
    pdf.text("Rua Julio de Castilhos, 2500" , x, y + 48);
    pdf.text("Taquara - RS" , x, y + 52);
    pdf.text("(51) 9 8660-4241", x, y + 56);

    // Gera o PDF
    pdf.save("Comprovante_Ticket.pdf");
  }

  const fazerPix = async () => {
    const placaString = textoPlaca.toString();
    const placaMaiuscula = placaString.toUpperCase();
    const placaFinal = placaMaiuscula.split("-").join("");
    if (textoPlaca === "") {
      setEstado(true);
      setMensagem("Preencha o campo placa");
      setTimeout(() => {
        setEstado(false);
        setMensagem("");
      }, 4000);
      return;
    }
    const sim = document.getElementById("flexSwitchCheckDefault").checked;
    if (!sim) {
      if (!validarPlaca(placaFinal)) {
        setEstado(true);
        setMensagem("Placa inválida");
        setTimeout(() => {
          setEstado(false);
          setMensagem("");
        }, 4000);
        return;
      }
    }
    setLoader(true);
    const valor = valorcobranca2.toString();
    const valor2 = parseFloat(valor.replace(",", ".")).toFixed(2);
    const campo = {
      placa: placaFinal,
      tempo: tempo,
    };
    const requisicao = axios.create({
      baseURL: process.env.REACT_APP_HOST,
    });
    requisicao
      .post("/gerarcobranca/estacionamento", {
        valor: valor2,
        campo: JSON.stringify(campo),
        tipo: "avulso",
      })
      .then((resposta) => {
        setLoader(false);
        if (resposta.data.msg.resultado) {
          setData(resposta.data.data);
          setTxId(resposta.data.data.txid);
          registrarEstacionamento(resposta.data.data.txid);
          setOnOpen(true);
          open();
        } else {
          setEstado(true);
          setMensagem(resposta.data.msg.msg);
          setTimeout(() => {
          setEstado(false);
          setMensagem("");
        }, 4000);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const FormatDate =  (date) => {
    const data = new Date(date);
    const year = data.getFullYear();
    const month = String(data.getMonth() + 1).padStart(2, '0');
    const day = String(data.getDate()).padStart(2, '0');
    const formattedDate = `${day}/${month}/${year}`;

    return formattedDate;
  }

  const registrarEstacionamento = async (Txid) => {
    const requisicao = axios.create({
      baseURL: process.env.REACT_APP_HOST,
      headers: {
        id_usuario: localStorage.getItem("user"),
        token_usuario: localStorage.getItem("token"),
        perfil_usuario: localStorage.getItem("perfil"),
      },
    });
    await requisicao
      .post("/estacionamento/avulso", {
        txid: Txid,
        tipo: "avulso",
      })
      .then((resposta) => {
        if (resposta.data.msg.resultado) {
          setData2(resposta.data.data);
          setDate(FormatDate(resposta.data.data.data));
          setEmissao(resposta.data.data.chegada)
          const validade = calcularValidade(resposta.data.data.chegada, resposta.data.data.tempo)
          setValidade(validade)
          openModal();
          setTextoPlaca("");
          setSuccess(true);
          setMensagem(resposta.data.msg.msg);
          setNotification(false);
          setEstado(false);
          setTimeout(() => {
            setSuccess(false);
            setMensagem("");
            setOnOpen(false);
            close();
            setTimeout(() => {
              setNotification(true);
            }, 2000);
          }, 3000);
        } else {
          setSuccess(false);
          setNotification(false);
          setPixExpirado("Pix expirado");
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <SidebarAvulso />

      <Modal
        opened={openedModal}
        onClose={() => {
          closeModal()
        }}
        centered
        size="xl"
        title="Comprovante de estacionamento:"
      >
        <div
          className="rounded border border-gray p-3 text-center"
          id="conteudoParaPDF"
        >
          <div className="mb-4">CONSEPRO TAQUARA</div>
          <Divider my="sm" size="md" variant="dashed" />
          <div>
            <h6>Tipo: Estacionamento avulso</h6>
          </div>
          <div>
            <h6>Início: {date} - {emissao} </h6>
          </div>
          <div>
            <h6>Validade: {date} - {validade} </h6>
          </div>
          <div>
            <h6>Placa: {data2.placa}</h6>
          </div>
          <div>
            <h6>Tempo: {data2.tempoCredito}</h6>
          </div>
          <div>
            <h6>Valor: R$ {data2.valor}</h6>
          </div>
          <Divider my="sm" size="md" variant="dashed" />
          CNPJ: 89.668.040/0001-10
          <br />
          Rua Julio de Castilhos, 2500
          <br />
          Taquara - RS
          <br />
          (51) 9 8660-4241
          <br />
        </div>

        <div className="mt-4">
          <Button
            variant="gradient"
            gradient={{ from: "teal", to: "indigo", deg: 300 }}
            size="md"
            radius="md"
            fullWidth
            rightIcon={<IconPrinter size={23} />}
            loaderPosition="right"
            onClick={() => {
              gerarPDF(data2);
            }}
          >
            Imprimir
          </Button>
        </div>
      </Modal>
      <div className="container mb-4">
        <div
          className="row justify-content-center form-bg-image"
          data-background-lg="../../assets/img/illustrations/signin.svg"
        >
          <div className="col-12 d-flex align-items-center justify-content-center">
            <div className="bg-white shadow border-0 rounded border-light p-4 p-lg-5 w-100 fmxw-500">
              <div className="h6 mt-1 mb-4 align-items-left text-start">
                Estacionamento avulso
              </div>

              <Divider my="sm" size="md" variant="dashed" />

              <div className="row">
                <div className="col-9 px-3 mt-1 pt-2">
                  <h6>Placa estrangeira/Outra</h6>
                </div>
                <div className="col-3 px-3">
                  <div className="form-check3 mt-2 form-switch gap-2 d-md-block">
                    <input
                      className="form-check-input align-self-end"
                      type="checkbox"
                      role="switch"
                      onClick={handlePlaca}
                      id="flexSwitchCheckDefault"
                      onChange={() => {
                        jae();
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="pt-1 mt-md-0 w-100 p-3" id={placa}>
                <input
                  type="text"
                  id={inputVazio}
                  className="mt-5 fs-1 justify-content-center align-items-center text-align-center"
                  value={textoPlaca}
                  onChange={(e) => setTextoPlaca(e.target.value)}
                  maxLength={limite}
                />
              </div>

              <Divider my="sm" size="md" variant="dashed" />

              <div className="h6 mt-3 mx-2">
                <Group position="apart">
                  <h6 className="mb-3">
                    <small>Determine o tempo (minutos): </small>
                  </h6>
                </Group>
                <Grid>
                  <Grid.Col span={3}>
                    <button
                      type="button"
                      className={`btn icon-shape icon-shape rounded align-center ${
                        selectedButton === "00:30:00"
                          ? "corTempoSelecionado"
                          : "corTempo"
                      }`}
                      onClick={() => handleButtonClick("00:30:00")}
                    >
                      <Text fz="lg" weight={700}>
                        30
                      </Text>
                    </button>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <button
                      type="button"
                      className={`btn icon-shape icon-shape rounded align-center ${
                        selectedButton === "01:00:00"
                          ? "corTempoSelecionado"
                          : "corTempo"
                      }`}
                      onClick={() => handleButtonClick("01:00:00")}
                    >
                      <Text fz="lg" weight={700}>
                        60
                      </Text>
                    </button>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <button
                      type="button"
                      className={`btn icon-shape icon-shape rounded align-center ${
                        selectedButton === "01:30:00"
                          ? "corTempoSelecionado"
                          : "corTempo"
                      }`}
                      onClick={() => handleButtonClick("01:30:00")}
                    >
                      <Text fz="lg" weight={700}>
                        90
                      </Text>
                    </button>
                  </Grid.Col>
                  <Grid.Col span={3}>
                    <button
                      type="button"
                      className={`btn icon-shape icon-shape rounded align-center ${
                        selectedButton === "02:00:00"
                          ? "corTempoSelecionado"
                          : "corTempo"
                      }`}
                      onClick={() => handleButtonClick("02:00:00")}
                    >
                      <Text fz="lg" weight={700}>
                        120
                      </Text>
                    </button>
                  </Grid.Col>
                </Grid>
                <div className="mt-3">
                  <p id="tempoCusto" className="text-end">
                    {" "}
                    Valor a ser cobrado: R$ {valorcobranca2}{" "}
                  </p>
                </div>
              </div>

              <div className="mb-2 mt-4 gap-2 d-md-block">
                <Button
                  onClick={() => {
                    fazerPix();
                  }}
                  variant="gradient"
                  gradient={{ from: "teal", to: "indigo", deg: 300 }}
                  size="md"
                  radius="md"
                  rightIcon={<IconCheck size={20} />}
                  loaderPosition="right"
                  loading={loader}
                >
                  Registrar
                </Button>
              </div>
              <div
                className="alert alert-danger mt-4"
                role="alert"
                style={{ display: estado ? "block" : "none" }}
              >
                {mensagem}
              </div>
              <div
                className="alert alert-success mt-4"
                role="alert"
                style={{ display: success ? "block" : "none" }}
              >
                {mensagem}
              </div>
            </div>
          </div>
        </div>
        <ModalPix
          qrCode={data.brcode}
          status={notification}
          mensagemPix={pixExpirado}
          onOpen={onOpen}
        />
      </div>
    </>
  );
};

export default EstacionamentoAvulso;

import { React, useState, useEffect  } from 'react';
import EstacionamentoAvulso from '../../components/EstacionamentoAvulso';
import 'bootstrap/dist/css/bootstrap.min.css'

const HomePage =  () => {
    const [data , setData] = useState('');
    const [cont, setCont] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            setCont(cont + 1)
        }, 500);
        setData(localStorage.getItem("componente"));
    }, [cont])

    return (
        <>
            <main className="main">
                    {data === '' ? null : <EstacionamentoAvulso />}
            </main>   
        </>
    )
}

export default HomePage;